.editor-container {
  width: 100%;
  padding: 8px;
  background: #fff;
  border: 2px solid #e7edee; /* MUI-like border */
  display: flex;
  flex-direction: column;
  margin-top: 26px;
  overflow-y: auto;
  height: 150px;
}

.editor-box {
  height: 80px;
  width: 100%;
  padding: 1px;
  outline: none;
  resize: none;
  font-size: 12px;
}

