.quill .ql-editor {
  min-height: 200px;
}

.rti--container {
  outline: 0 !important;
  border-width: 0 0 1px !important;
  border-color: #d2d6da !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  z-index: 9999;
}
.rti--container:focus {
  border-color: #1a74bb !important;
}

.no-pt-element {
  padding-top: 0 !important;
}

.rti--tag {
  background-color: black;
  color: white;
}

.rti--tag button {
  background-color: black;
  color: white;
}

.css-1fkh2rf-MuiAutocomplete-root .MuiAutocomplete-tag {
  background-color: white !important;
  color: black !important;
  padding-left: 0 !important;
}

.css-1fkh2rf-MuiAutocomplete-root .MuiAutocomplete-tag .MuiChip-label {
  padding-left:0 !important;
}

.css-1fkh2rf-MuiAutocomplete-root .MuiAutocomplete-tag .MuiSvgIcon-root, .css-1fkh2rf-MuiAutocomplete-root .MuiAutocomplete-tag .MuiSvgIcon-root:hover, .css-1fkh2rf-MuiAutocomplete-root .MuiAutocomplete-tag .MuiSvgIcon-root:focus {
  color: black !important;
}

