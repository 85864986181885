.dashboard-button.Mui-disabled {
  color:white !important;
  opacity: .75;
  cursor: not-allowed !important;
}

.MuiCheckbox-root.Mui-disabled svg {
  background-image: none !important;
  background-color: rgba(0, 0, 0, 0.123);
  border: rgba(0, 0, 0, 0.123);
}