@import 'datatables.net-dt';
@import 'datatables.net-responsive-dt';
@import 'datatables.net-select-dt';

/* Personalização do DataTables para combinar com o tema do Material Dashboard */

.dataTables_wrapper {
  padding: 1rem;
}

.dataTables_filter {
  float: right;
  margin-bottom: 1rem;
}

.dataTables_filter input {
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #d2d6da;
}

.dataTables_length select {
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #d2d6da;
  margin: 0 0.5rem;
}

table.dataTable thead th {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: 600;
}

table.dataTable tbody td {
  padding: 0.75rem 1rem;
  vertical-align: middle;
}

table.dataTable.stripe tbody tr.odd,
table.dataTable.display tbody tr.odd {
  background-color: rgba(0, 0, 0, 0.02);
}

table.dataTable.hover tbody tr:hover,
table.dataTable.display tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.dataTables_info {
  padding-top: 1rem;
}

.dataTables_paginate {
  padding-top: 1rem;
  float: right;
}

.dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  border-radius: 2px;
}

.dataTables_paginate .paginate_button.current,
.dataTables_paginate .paginate_button.current:hover {
  color: white !important;
  background: #1A73E8;
}

.dataTables_paginate .paginate_button:hover {
  background: #e9f0fd;
}

/* Modo escuro */
.dark-mode table.dataTable thead th {
  background-color: #202940;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.dark-mode table.dataTable tbody td {
  color: #fff;
}

.dark-mode table.dataTable.stripe tbody tr.odd,
.dark-mode table.dataTable.display tbody tr.odd {
  background-color: rgba(255, 255, 255, 0.05);
}

.dark-mode table.dataTable.hover tbody tr:hover,
.dark-mode table.dataTable.display tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

div.dt-container div.dt-layout-row:not(.dt-layout-table) {
  padding-left: 1rem;
  padding-right: 1rem;
}