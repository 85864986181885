@media (min-width: 600px) {
  .policy-wizard-container {
    /* margin-top: 50px; */
    padding-left: 20px;
    padding-right: 20px;
  }
}

.MuiStepper-root.create-policy-wizard-stepper {
  /*background: linear-gradient(195deg, #49a3f1, #1a74bb);*/
  /*background-image: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));*/
  /*background-position-x: initial;*/
  /*background-position-y: initial;*/
  /*background-size: initial;*/
  /*background-repeat-x: initial;*/
  /*background-repeat-y: initial;*/
  /*background-attachment: initial;*/
  /*background-origin: initial;*/
  /*background-clip: initial;*/
  /*background-color: initial;*/

  background: linear-gradient(195deg, #38b54a, #38b54a);
  background-image: linear-gradient(195deg, #38b54a, #38b54a);
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
}

.dark-theme .MuiStepper-root.create-policy-wizard-stepper {
  background: linear-gradient(195deg, #66BB6A, #66BB6A);
  background-image: linear-gradient(195deg, #66BB6A, #66BB6A);
}

.dark-theme .MuiStepper-root.create-policy-wizard-stepper .MuiStepConnector-root.Mui-disabled,
.dark-theme .MuiStepper-root.create-policy-wizard-stepper .MuiStepLabel-label.Mui-disabled {
  color: #ffffff4b;
}

.MuiStepper-root.create-policy-wizard-stepper .MuiStepConnector-root.Mui-disabled,
.MuiStepper-root.create-policy-wizard-stepper .MuiStepLabel-label.Mui-disabled {
  color: #90c1a6;
}

.MuiStepper-root.create-policy-wizard-stepper .MuiStepConnector-lineVertical {
  min-height: 12px;
}

.MuiStepper-root.create-policy-wizard-stepper .MuiStepLabel-vertical {
  padding: 0;
}

.MuiStepper-root.create-policy-wizard-stepper .MuiStepLabel-vertical .MuiStepLabel-label {
  margin: 0 !important;
}

.MuiStepper-root.create-policy-wizard-stepper .MuiStepLabel-iconContainer.Mui-disabled .MuiSvgIcon-root {
  fill: #90c1a6;
  stroke: #90c1a6;
  background: #90c1a6;
}

.dark-theme .MuiStepper-root.create-policy-wizard-stepper .MuiStepLabel-iconContainer.Mui-disabled .MuiSvgIcon-root {
  fill: #ffffff4b;
  stroke: #ffffff4b;
  background: #ffffff4b;
}