.gamification-sidebar {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    transition: all 0.3s ease;
    z-index: 10000;
}

.gamification-sidebar-content {
    position: relative;
    padding: 20px;
    overflow-y: auto;
    height: 100vh;
}

.gamification-sidebar.closed {
    width: 0;
}

.gamification-sidebar.open {
    width: 500px; /* Sidebar width when open */
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    background-color: #698aa5; /* Sidebar background color */
}

.bar-chart-icon {
    color: #a77400;
}

.toggle-button {
    position: absolute;
    top: 25%;
    left: -45px;
    width: 45px;
    height: 60px;
    background-color: #f9c142;
    border: 2px solid #a77400;
    border-right: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px 0 0 5px;
    transform: translateY(-50%);
    transition: .3s ease;
    opacity: 1;
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #0a2e4a;
    font-weight: bold;
}

.gamification-sidebar.open .toggle-button {
 opacity: 0;
}

.sidebar-tabs {
    margin-top: 50px;
    background-color: #698aa5 !important;
    overflow: visible !important;
    border-bottom: 1px solid #0a2e4a !important;
    padding: 0 !important;
    border-radius: 0 !important;
}

.sidebar-tabs .sidebar-tab {
    top: 1px;
    border: 1px solid #0a2e4a !important;
    background: #9ba6b1 !important;
    color: #0a2e4a !important;
}

.sidebar-tabs .tab-active {
    border-bottom: none !important;
    background-color: #a2b2c1 !important;
    color: #0a2e4a !important;
}

span.MuiTabs-indicator {
    display: none;
}

.tab-content {
    padding: 10px;
    background-color: #a2b2c1;
    color: #0a2e4a;
    border: 1px solid #0a2e4a;
    border-top: none;
}

.tab-content p {
    padding: 0 0 10px;
    font-size: 14px;
}

.tab-content ul, .tab-content ol {
    list-style: inside;
}

.tab-content h3 {
    margin: 10px 0 6px;
}
.tab-content h4 {
    margin: 0 0 8px;
}

.tab-content table {
    width: 100%;
    text-align: left;
    border: 1px solid #0a2e4a;
    border-spacing: 0;
}
.tab-content th {
    background: #426682;
}
.tab-content td, .tab-content th {
    padding: 4px 6px;
    color: #ededed;
}

.tab-content tr:nth-child(even) {
    background: #28455b;
}
.tab-content tr:nth-child(odd) {
    background: #345670;
}
.tab-content .first-place td {
    color: #ea9523;
    font-weight: bold;
}
.tab-content .second-place td {
    color: #bababa;
    font-weight: bold;
}
.tab-content .third-place td {
    color: #b47760;
    font-weight: bold;
}
.award-icon {
    top: 4px;
    position: relative;
}