.print {
  visibility: hidden;
  height: 0px !important;
  overflow: hidden;
}

.page-break{
  page-break-before: always
}

@media print {
  .no-print {
    display: none !important;
  }
  .print {
   visibility: visible;
   height: max-content !important;
  }
}
