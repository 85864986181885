.dashboard-button.Mui-disabled {
  color:white !important;
  opacity: .75;
  cursor: not-allowed !important;
}


.emailPreferenceRadioDiv{
  padding:0.8rem;
  border:1px solid #e7edee;
  width:100%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}