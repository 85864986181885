@media screen and (max-width: 767px){
    .top-header-menu {
        display: none;
    }
}
/* #onboardingStepsDiv {
    height: auto;
    overflow-y: auto;
  }
  
  #onboardingStepsDiv ul {
    list-style-type: none;
    padding: 0;
  }
  
  #onboardingStepsDiv li {
    margin-bottom: 10px;
  } */


div#onboardingStepsDiv {
    background-color: white;
    width: 420px;
    position: absolute;
    top: 40px;
    right: -25px;
    transform: scale(0);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform-origin: 90% 0%;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    box-shadow: 0 5px 80px rgba(0, 0, 0, .25);
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
}

div#onboardingStepsDiv::before {
    content: "";
    position: absolute;
    top: -8px;
    right: 40px;
    border-width: 0 10px 10px 10px;
    border-style: solid;
    border-color: transparent transparent #1a74bb transparent;
    display: block;
    width: 0;
    height: 0;
}



div#onboardingStepsDivHeader {
    width: 100%;
    background-color: #1a74bb;
    border-radius: 16px 16px 0 0;
    -webkit-border-radius: 16px 16px 0 0;
    -moz-border-radius: 16px 16px 0 0;
    -ms-border-radius: 16px 16px 0 0;
    -o-border-radius: 16px 16px 0 0;
    color: white;
    padding: 2rem 1.5rem;
    font-size: 1.1rem;
    height: auto;
}


div#onboardingStepsContent {
    padding: 0.2rem 1.5rem;
    width: 100%;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
}