a.tooltip-link,
a.tooltip-link:hover,
a.tooltip-link:visited {
  color: #38b54a;
  text-decoration: none;
}

p {
  padding: 10px;
}

.MuiTabs-centered {
  border-bottom: #888 thin solid;
}

.MuiTab-root {
  border-bottom: none !important;
  border-radius: 5px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding: 8px !important;
  margin-right: 10px !important;
  border: thin solid #888 !important;
  border-bottom: none !important;
}

.MuiTabs-centered .Mui-selected {
  background-color: #38b54a !important;
  /* padding: 8px; */
  border: #38b54a thin solid !important;
  color: white !important;
}

.MuiTab-root:last-child{
  margin-right: 0 !important;
}
