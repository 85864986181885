@font-face {
    font-family: "NexaLight";
    src: url("font/Nexa-Light.ttf") format("truetype");
    font-weight: lighter;
}

@font-face {
    font-family: "NexaRegular";
    src: url("font/NexaRegular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "NexaHeavy";
    src: url("font/NexaHeavy.ttf") format("truetype");
    font-weight: bold;
}


.hero-text {
    font-family: "NexaLight", sans-serif;
}

span.landing-page-links {
    display: inline-block;
    background-color: white;
    font-family: "NexaHeavy", Roboto, Helvetica, Arial, sans-serif;
    padding: 0.5rem 1.8rem;
    color: rgb(36, 120, 168);
    font-size: 1.4rem;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}


.footer-info {
    font-family: "NexaLight", Roboto, Helvetica, Arial, sans-serif;
}

span.footer-links {
    display: inline-block;
    background-color: transparent;
    font-family: "NexaRegular", Roboto, Helvetica, Arial, sans-serif;
    padding: 0.4rem 1.8rem;
    font-weight: bold;
    color: white;
    border: 3px solid white;
    font-size: 0.9rem;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}