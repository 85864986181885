.quill .ql-editor {
  min-height: 200px;
}

.rti--container {
  outline: 0 !important;
  border-width: 0 0 1px !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background-color: transparent;
  z-index: 9999;
}

.light-theme .rti--container {
  border-color: #d2d6da !important;
}

.dark-theme .rti--container {
  border-color: #d2d6daaa !important;
}

.rti--container:focus {
  border-color: #1A73E8 !important;
}

.tags-label {
  font-size: 0.875rem;
  line-height: 0.9;
}

.light-theme .tags-label {
  color: #7b809a;
}

.dark-theme .tags-label {
  color: #f3f6ff;
}

.no-pt-element {
  padding-top: 0 !important;
}

.rti--tag {
  background-color: black;
  color: white;
  border-radius: 25px;
  padding: 5px 3px 5px 10px;
  font-size: 14px;
}

.rti--tag span {
  border-right: 1px solid #aaa;
  padding-right: 7px;
  line-height: 12px;
}

.rti--tag button {
  background-color: black;
  color: #eee;
  padding: 0 7px;
  font-size: 11px;
}

.rti--input {
  background-color: transparent;
}

.light-theme .rti--input {
  color: #7b809a;
}

.dark-theme .rti--input {
  color: #f3f6ff;
}

div.dangerousHTMLDIVClass ul {
  list-style-type: disc !important;
}
div.dangerousHTMLDIVClass ol {
  list-style-type: decimal !important;
}