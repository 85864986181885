.table-reviewers-container {
  margin: 2rem 0;
  font-size: 14px;
}

.table-reviewers-container table td,
.table-reviewers-container table th {
  padding: 8px 24px;
  text-align: left;
}

.table-reviewers-container table td.pl-0,
.table-reviewers-container table th.pl-0 {
  padding-left: 0;
}

.table-reviewers-container table td.text-success {
  color: #4DA851;
}

.table-reviewers-container table td.text-warning {
  color: orange;
}

.table-reviewers-container table td.text-danger {
  color: orangered;
}

.table-responsive-header {
  display: none;
}

.table-responsive-container > .table-responsive-row:not(:first-child) .table-responsive-header {
  display: block;
}

.table-responsive-container > .table-responsive-row {
  display: block;
  border: 1px solid #F2F2F3;
  padding: 12px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 2px 2px 0px rgba(213,214,217,1);
  -moz-box-shadow: 0px 2px 2px 0px rgba(213,214,217,1);
  box-shadow: 0px 2px 2px 0px rgba(213,214,217,1);
  margin-top:8px;
}



.table-responsive-container > .table-responsive-row .reviewer-reason {
  display: none;
}

.table-responsive-container > .table-responsive-row .reviewer-reason.show-reason {
  display: block;
}

.step-content-wrapper {
  padding: 0;
}

.table-responsive-container > .table-responsive-row:first-child {
  display: none;
}

@media (min-width: 992px) {
  .step-content-wrapper {
    padding: 0 40px;
  }

  .table-responsive-container > .table-responsive-row {
    display: block;
    border: 1px solid #F2F2F3;
    padding: 12px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;

    margin-top:2px;
  }

  .table-responsive-container > .table-responsive-row:first-child {
    -webkit-border-top-left-radius: 4px;
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topleft: 4px;
    -moz-border-radius-topright: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #F0F2F5;
    display: block;
  }

  .table-responsive-container > .table-responsive-row:first-child .table-responsive-header {
    display: block;
  }

  .table-responsive-container > .table-responsive-row:last-child {
    -webkit-border-bottom-right-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .table-responsive-container > .table-responsive-row:not(:first-child) .table-responsive-header {
    display: none;
  }

  .table-responsive-container > .table-responsive-row {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .reviewer-reason > .MuiBox-root {
    display: block;
    border: 1px solid #F2F2F3;
    border-left: 4px solid #f44335;
    padding: 12px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: rgb(253, 252, 199);
    /* background: rgb(236, 127, 127); */
  }

  .reviewer-reason .reviewer-reason-header {
    display: none;
  }
}
