ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol,
ul {
  padding-left: 2rem;
  font-size: 1rem;
  list-style-type: none;
}

::marker {
  unicode-bidi: isolate;
  font-variant-numeric: tabular-nums;
  text-transform: none;
  text-indent: 0px !important;
  text-align: start !important;
  text-align-last: start !important;
}

ul li a {
  letter-spacing: 0rem;
  color: #0b2e4a;
  text-decoration: none;
}
