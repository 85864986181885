/* .MuiPaper-root.hl-border, .hl-border {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
} */

.MuiPaper-root.bg-light,
.bg-light {
  background-color: #f7f8f9;
}

.custom-slider .MuiBox-root {
  background-color: #ffffff;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.custom-slider .MuiSlider-track {
  background-color: #4caf50;
}

.custom-slider .MuiSlider-rail {
  background-color: #b1bfb1;
}

.custom-slider .MuiSlider-thumb {
  border-color: #22c907;
}

.custom-slider .MuiSlider-markLabel {
  color: #1a1a1a;
  font-weight: 500;
}

.custom-slider .MuiSlider-valueLabel {
  background-color: rgba(255, 255, 255, 1);
  font-size: 14px;
  /* border: 1px solid lightslategray; */
  -webkit-box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.75);
}

.MuiPaper-root.custom-card {
  -webkit-border-radius: 0.75rem;
  -moz-border-radius: 0.75rem;
  border-radius: 0.75rem;
  background-color: #ffffff;

  box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.12) !important;

  /* -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75); */
}

.MuiPaper-root.custom-card .card-header {
  -webkit-border-top-left-radius: 0.75rem;
  -webkit-border-top-right-radius: 0.75rem;
  -moz-border-radius-topleft: 0.75rem;
  -moz-border-radius-topright: 0.75rem;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;

  background-color: gainsboro;
}

.MuiPaper-root.custom-card .card-header.bg-success {
  color: #ffffff;
  background-color: #4caf50;
}

.MuiPaper-root.custom-card .card-header.bg-info {
  color: #ffffff;
  background-color: #1a74bb;
}

.MuiPaper-root.custom-card .card-header.bg-secondary {
  color: #ffffff;
  background-color: #1c9dbb;
}

.all-plans-include-section ul {
  list-style: disc;
}

.custom-slider {
  width: calc(100% - 25px);
  max-width: 400px;
}

.slider-container {
  width: calc(100% - 5px);
  max-width: 390px;
}

.MuiPaper-root.custom-card .all-plans-include-section {
  padding-left: 20px;
}

.MuiPaper-root.custom-card .all-plans-include-section li span {
  color: #0b2e4a;
  font-size: 16px;
}

.MuiPaper-root.custom-card .card-content-text {
  color: #7b809a;
  font-size: 16px;
}

.MuiPaper-root.custom-card .price-month {
  color: #0b2e4a;
  font-size: 20px;
  letter-spacing: -0.05rem;
  font-weight: 600;
}

.MuiPaper-root.custom-card .price-month-label {
  color: #7b809a;
  font-size: 16px;
  letter-spacing: -0.05rem;
  font-weight: 400;
}

.MuiPaper-root.custom-card .price-per-client {
  color: #4baf50;
  font-size: 30px;
  letter-spacing: -0.05rem;
  font-weight: 600;
}
.MuiPaper-root.custom-card .price-per-client-plus {
  color: #1c9dbb;
  font-size: 30px;
  letter-spacing: -0.05rem;
  font-weight: 600;
}

.MuiPaper-root.custom-card .price-client-label {
  margin-right: 1rem;
}

.MuiPaper-root.custom-card.custom-product-card {
  width: calc(100% - 25px);
  max-width: 400px;
}

@media (min-width: 992px) {
  .custom-slider {
    width: calc(100% - 40px);
    max-width: 640px;
  }

  .slider-container {
    width: calc(100% - 10px);
    max-width: 620px;
  }

  .MuiPaper-root.custom-card.custom-product-card {
    width: calc(100% - 40px);
    max-width: 640px;
  }
}
