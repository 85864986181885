.quill .ql-editor {
  min-height: 200px;
}

.rti--container {
  outline: 0 !important;
  border-width: 0 0 1px !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 0px;
  background-color: transparent;
  z-index: 9999;
}

.light-theme .rti--container {
  border-color: #d2d6da !important;
}

.dark-theme .rti--container {
  border-color: #d2d6daaa !important;
}

.rti--container:focus {
  border-color: #1a74bb !important;
}

.tags-label {
  font-size: 0.775rem;
  line-height: 1.5;
  vertical-align: top;
}

.light-theme .tags-label {
  color: #7b809a;
}

.dark-theme .tags-label {
  color: #f3f6ff;
}

.no-pt-element {
  padding-top: 0 !important;
}

.rti--tag {
  background-color: black !important;
  color: white !important;
  padding: 0px 3px 5px 10px;
  border-radius: 25px;
  font-size: 14px;
}

.rti--tag button {
  background-color: black !important;
  color: white !important;
  padding: 0 7px;
  font-size: 11px;
}

.rti--tag span {
  border-right: 0 !important;
  padding-right: 5px !important;
  line-height: 12px;
}

.MuiAutocomplete-option.Mui-focused {
  background-color: rgba(0, 0, 0, .20) !important;
}

.rti--input {
  background-color: transparent;
}

.light-theme .rti--input {
  color: #7b809a;
}

.dark-theme .rti--input {
  color: #f3f6ff;
}


.css-knlq9b-MuiFormControlLabel-root {
  margin-bottom: 0;
}

.css-knlq9b-MuiFormControlLabel-root .css-tnsvld-MuiSwitch-root {
  height: 30px;
  padding: 8px;
}

.css-knlq9b-MuiFormControlLabel-root .css-11rehke-MuiSwitch-thumb {
  width: 14px;
  height: 13px;
}