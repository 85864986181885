div.expanded {
    transform: translate(25%, 0);
    -webkit-transform: translate(25%, 0);
    -moz-transform: translate(25%, 0);
    -ms-transform: translate(25%, 0);
    -o-transform: translate(25%, 0);
}

div.not-expanded {
    transform: translate(25%, 2000px);
    -webkit-transform: translate(25%, 2000px);
    -moz-transform: translate(25%, 2000px);
    -ms-transform: translate(25%, 2000px);
    -o-transform: translate(25%, 2000px);
}