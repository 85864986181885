.auto_c .MuiAutocomplete-popupIndicator{
    color:white;
}

.auto_c{
    position:relative;
    width: 270px;
    padding: 0px !important; 
}

div.switcher-overlay{
    width:100%;
    height:100%;
    background:rgba(255,255,255,0.9);
    position:absolute;
    top:0;
    display:flex;
    justify-content: center;
    align-items: center;
    left:0;
    z-index:1;
    padding:0.3rem;
}

@media print {
  .no-print {
    display: none !important;
  }
  .print {
   visibility: visible;
   height: max-content !important;
  }
}