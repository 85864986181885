div.expanded {
    transform: translate(45%, 0) !important;
    -webkit-transform: translate(45%, 0) !important;
    -moz-transform: translate(45%, 0) !important;
    -ms-transform: translate(45%, 0) !important;
    -o-transform: translate(45%, 0) !important;
}

div.not-expanded {
    transform: translate(45%, 2000px) !important;
    -webkit-transform: translate(45%, 2000px) !important;
    -moz-transform: translate(45%, 2000px) !important;
    -ms-transform: translate(45%, 2000px) !important;
    -o-transform: translate(45%, 2000px) !important;
}